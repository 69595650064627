import React from 'react';
import { createRoot } from 'react-dom/client';
import TypeaheadInput from './components/TypeaheadInput';
import reportWebVitals from './reportWebVitals';


const defaultSettings = window.wpTypeaheadSettings || {
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
  baseId: process.env.REACT_APP_AIRTABLE_BASE_ID,
  table: process.env.REACT_APP_AIRTABLE_TABLE_NAME,
  labelFieldId: process.env.REACT_APP_AIRTABLE_LABEL_FIELD_ID,
  labelFieldName: process.env.REACT_APP_AIRTABLE_LABEL_FIELD_NAME,
  identifierField: process.env.REACT_APP_AIRTABLE_LABEL_IDENTIFIER,
  customClass: process.env.REACT_APP_CUSTOM_CLASS,
  dataSource: 'airtable'
};

document.querySelectorAll('.react-typeahead-container').forEach((rootElement) => {
  const uniqueId = rootElement.id;
  const settingsVarName = 'wpTypeaheadSettings_' + uniqueId.replace(/[^a-zA-Z0-9_]/g, '_');
  const specificSettings = window[settingsVarName] || {};
  const finalSettings = { ...defaultSettings, ...specificSettings, uniqueId };

  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <TypeaheadInput
        {...finalSettings}
      />
    </React.StrictMode>
  );
});

reportWebVitals(console.log);
