import React, { useState, useEffect } from 'react';
import { DebounceInput } from 'react-debounce-input';
import TypeaheadList from './TypeaheadList';
import { fetchSuggestions, generateUrl } from '../utils/dataService';
import '../styles/styles.css';

const knownLanguages = [
  'Mandarin', 'Spanish', 'English', 'Hindi', 'Bengali',
  'Portuguese', 'Russian', 'Japanese', 'Arabic',
  'Quechua', 'Telugu', 'Swahili', 'Turkish', 'Korean',
  'French'
];

const TypeaheadInput = ({ apiKey, baseId, table, debounceTime = 300, labelFieldId, labelFieldName, identifierField, customClass, uniqueId, dataSource }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selected, setSelected] = useState(false);
  const [placeholder, setPlaceholder] = useState('Find a language...');
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchPlaceholder = async () => {
      const results = await fetchSuggestions({
        apiKey,
        baseId,
        table,
        labelFieldId,
        labelFieldName,
        identifierField,
        dataSource
      }, '');
      const randomSuggestions = results.sort(() => 0.5 - Math.random()).slice(0, 2);
      const randomKnownLanguage = knownLanguages[Math.floor(Math.random() * knownLanguages.length)];
      const placeholderText = [randomKnownLanguage, ...randomSuggestions.map(s => s.label.split(',')[0])].join(', ');
      setPlaceholder(`Explore ${placeholderText}...`);
      setLoaded(true);  // Set loaded to true once the placeholder has been set

      const instances = document.getElementsByClassName('react-typeahead-container');
      for (let index = 0; index < instances.length; index++) {
        const element = instances[index];
        element.style.display = 'block';  // Make the container visible
      }

      // Ensure the container exists before trying to access it
      const container = document.getElementById(uniqueId);
      if (container) {
        container.style.display = 'block';
      } else {
        console.error(`Container with ID ${uniqueId} not found.`);
      }
    };

    fetchPlaceholder();
  }, [apiKey, baseId, table, labelFieldId, labelFieldName, identifierField, uniqueId, dataSource]);

  const handleChange = async (event) => {
    const value = event.target.value;
    setQuery(value);
    setSelected(false);  // Reset selected state on input change
    if (value) {
      const results = await fetchSuggestions({
        apiKey,
        baseId,
        table,
        labelFieldId,
        labelFieldName,
        identifierField,
        dataSource
      }, value);

      // Sorting: prioritize perfect identifier matches, then by label
      const sortedResults = results.sort((a, b) => {
        const isIdentifierMatchA = a.identifier.toLowerCase() === value.toLowerCase();
        const isIdentifierMatchB = b.identifier.toLowerCase() === value.toLowerCase();

        if (isIdentifierMatchA && !isIdentifierMatchB) return -1;
        if (!isIdentifierMatchA && isIdentifierMatchB) return 1;
        return a.label.localeCompare(b.label);
      });

      setSuggestions(sortedResults);
      setSelectedIndex(-1);
    } else {
      setSuggestions([]);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, suggestions.length - 1));
    } else if (event.key === 'ArrowUp') {
      setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (event.key === 'Enter' && selectedIndex >= 0) {
      handleSelect(suggestions[selectedIndex]);
      event.preventDefault();  // Prevent form submission on suggestion select
    } else if (event.key === 'Escape') {  // Added this block
      setQuery('');
      setSuggestions([]);
      setSelectedIndex(-1);
      setSelected(false);
      event.target.blur();
    }
  };

  const handleSelect = (suggestion) => {
    const identifier = suggestion.identifier;
    const url = generateUrl(`/languages/${identifier}/`);
    setSelected(true);  // Set selected state
    window.location.href = url;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selected) {
      // If no suggestion is selected, route to the general search results
      const searchUrl = generateUrl(`/?s=${encodeURIComponent(query)}`);
      window.location.href = searchUrl;
    }
  };

  return (
    <form id={uniqueId} className={`typeahead-form${customClass ? ` ${customClass}` : ''}${loaded ? ' loaded' : ''}`} onSubmit={handleSubmit}>
      <DebounceInput
        className={`typeahead-input${customClass ? ` ${customClass}` : ''}`}
        minLength={2}
        debounceTimeout={debounceTime}
        onChange={handleChange}
        value={query}
        placeholder={placeholder}
        aria-autocomplete="list"
        aria-controls="typeahead-list"
        aria-activedescendant={selectedIndex >= 0 ? `suggestion-${selectedIndex}` : undefined}
        onKeyDown={handleKeyDown}
      />
      {suggestions.length > 0 && (
        <TypeaheadList
          suggestions={suggestions}
          query={query}
          selectedIndex={selectedIndex}
          onSelect={handleSelect}
        />
      )}
    </form>
  );
};

export default TypeaheadInput;
