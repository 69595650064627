import axios from 'axios';
import { fetchSuggestions as fetchSuggestionsFromAirtable } from './airtable';

export const generateUrl = (path) => {
  const baseUrl = window.location.hostname === 'localhost' ? `${window.location.origin}/wikitongues` : window.location.origin;
  return `${baseUrl}${path}`;
};

const fetchSuggestionsFromWordPress = async (query, perPage = 100) => {
  const url = generateUrl(`/wp-json/custom/v1/search`);
  try {
    const response = await axios.get(url, {
      params: { query, per_page: perPage }
    });

    if (response.status !== 200) {
      console.error('Error fetching suggestions:', response.status, response.statusText);
      return [];
    }

    return response.data
      .filter(item => item.iso_code && item.alternate_names && item.iso_code.trim() !== '' && item.alternate_names.trim() !== '')
      .map(item => ({
        id: item.id,
        identifier: item.iso_code,
        label: item.alternate_names,
        nations_of_origin: item.nations_of_origin || ''
      }));
  } catch (error) {
    console.error('Error fetching suggestions:', error.response ? error.response.data : error.message);
    return [];
  }
};

export const fetchSuggestions = async (config, query) => {
  const {dataSource, ...sourceConfig } = config;
  if (dataSource === 'airtable') {
    return await fetchSuggestionsFromAirtable(
      sourceConfig.apiKey,
      sourceConfig.baseId,
      sourceConfig.table, 
      query,
      sourceConfig.labelFieldId,
      sourceConfig.labelFieldName,
      sourceConfig.identifierField
    );
  } else if (dataSource === 'wordpress') {
    return await fetchSuggestionsFromWordPress(query);
  }

  return [];
};